import React from "react"
import { Link } from "gatsby"

import tipico from "../../static/images/logos/custom/tipico.png"

export default function Partners({ translationMessages }) {
  return (
    <div className="section is-medium is-relative">
      <div className="container">
        <div className="section-title-wrapper has-text-centered">
          <div className="bg-number">9</div>
          <h1 className="section-title-landing">{translationMessages.title}</h1>
          <h4>{translationMessages.subtitle}</h4>
        </div>
        <div className="content-wrapper">
          <div className="grid-clients five-grid">
            <div className="columns is-vcentered">
              <div className="column">
                <Link to="https://www.tipico-shopagency.de/">
                  <img className="client" src={tipico} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="has-text-centered is-title-reveal pt-40 pb-40">
            <Link
              to="#form"
              className="button button-cta btn-align raised rounded secondary-btn"
            >
              {translationMessages.getStarted}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
