import React from "react"

export default function Services({ translationMessages }) {
  return (
    <div id="services" className="section is-medium">
      <div className="container">
        <div className="section-title-wrapper has-text-centered">
          <div className="bg-number">1</div>
          <h1 className="section-title-landing">{translationMessages.title}</h1>
          <h4>{translationMessages.subtitle}</h4>
        </div>

        <div className="content-wrapper">
          <div className="columns is-vcentered is-multiline has-text-centered">
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Check"></i>
                </div>
                <div className="box-title">
                  {translationMessages.taskManagement.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.taskManagement.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Bar-Chart"></i>
                </div>
                <div className="box-title">
                  {" "}
                  {translationMessages.analytics.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.analytics.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Robot-2"></i>
                </div>
                <div className="box-title">
                  {translationMessages.automation.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.automation.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Calendar-4"></i>
                </div>
                <div className="box-title">
                  {" "}
                  {translationMessages.calendar.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.calendar.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-MaleFemale"></i>
                </div>
                <div className="box-title">
                  {" "}
                  {translationMessages.userManagment.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.userManagment.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Tag"></i>
                </div>
                <div className="box-title">
                  {" "}
                  {translationMessages.tags.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.tags.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Tree"></i>
                </div>
                <div className="box-title">
                  {" "}
                  {translationMessages.hierarchy.title}
                </div>
                <p className="box-content is-tablet-padded">
                  {translationMessages.hierarchy.description}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="chat-icon-box">
                <div>
                  <i className="im im-icon-Rocket"></i>
                </div>
                <div className="box-title">   {translationMessages.more.title}</div>
                <p className="box-content is-tablet-padded">
                {translationMessages.more.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}