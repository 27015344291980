import React from "react"

export default function SignUp({ translationMessages }) {
  return (
    <div
      className="section is-medium is-relative section-feature-grey footer-wavy"
      id="form"
    >
      <div className="container">
        <div className="section-title-wrapper has-text-centered">
          <div className="bg-number">10</div>
          <h1 className="section-title-landing">{translationMessages.title}</h1>
          <h4>{translationMessages.subtitle}</h4>
        </div>
        <div className="content-wrapper">
          <form
            action="https://getform.io/f/00253aa2-cc5e-4e2c-95fd-0a3529cf2b95"
            method="POST"
          >
            <div className="columns huge-pb">
              <div className="column is-12 is-offset-2">
                <div className="columns is-vcentered">
                  <div className="column is-2">
                    <div className="control-material is-primary">
                      <input
                        className="material-input"
                        type="text"
                        name="name"
                        required
                      />
                      <span className="material-highlight"></span>
                      <span className="bar"></span>
                      <label> {translationMessages.name}</label>
                    </div>
                  </div>
                  <div className="column is-2">
                    <div className="control-material is-primary">
                      <input
                        className="material-input"
                        type="text"
                        name="email"
                        required
                      />
                      <span className="material-highlight"></span>
                      <span className="bar"></span>
                      <label> {translationMessages.email}</label>
                    </div>
                  </div>
                  <div className="column is-2">
                    <div className="control-material is-primary">
                      <input
                        className="material-input"
                        type="text"
                        name="company"
                        required
                      />
                      <span className="material-highlight"></span>
                      <span className="bar"></span>
                      <label> {translationMessages.company}</label>
                    </div>
                  </div>
                  <div className="column is-2">
                    <div className="control-material ">
                      <select
                        className="material-input"
                        name="plan"
                        defaultValue="Basic"
                        required
                        id="plan"
                      >
                        <option value="Mini">Starter</option>
                        <option value="Medium">Basic</option>
                        <option value="Individual">Individual</option>
                        <option value="Ultimate">Enterprise</option>
                      </select>
                      <span className="material-highlight"></span>
                      <span className="bar"></span>
                    </div>
                  </div>
                  <div className="column is-2">
                    <button
                      type="submit"
                      className="button button-cta btn-align primary-btn btn-outlined is-bold rounded raised no-lh"
                    >
                      {translationMessages.signMeUp}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
