import React from "react"
import { Link } from "gatsby"

import logo_white from "../../static/images/logos/tasktility-logo-white.svg"
import logo_black from "../../static/images/logos/tasktility-logo-black.svg"
import hero from "../../static/images/illustrations/interface/dashboard_muia.png"

function Header({ translationMessages, language }) {
  const getLanguageIcon = menuLanguage => {
    return menuLanguage === language
      ? "im im-icon-Yes mr-2"
      : "im im-icon-Remove mr-2"
  }

  const getActiveClass = menuLanguage => {
    return menuLanguage === language ? "dropdown-item-active" : ""
  }

  const getActiveLanguage = () => {
    switch (language) {
      case "en":
        return "English"
      case "de":
        return "Deutsch"
      case "ba":
        return "Bosanski"
      default:
        break
    }
  }

  return (
    <div className="hero hero-wavy is-relative is-theme-primary huge-pb">
      <nav className="navbar navbar-wrapper navbar-fade navbar-light is-transparent">
        <div className="container ">
          <div className="navbar-brand">
            <Link className="navbar-item" to="#form">
              <img
                className="light-logo"
                src={logo_white}
                alt="tasktility-logo-white"
              />
              <img
                className="dark-logo"
                src={logo_black}
                alt="tasktility-logo-black"
              />
            </Link>
            <div className="custom-burger">
              <Link id="" className="responsive-btn">
                <span className="menu-toggle">
                  <span className="icon-box-toggle">
                    <span className="rotate">
                      <i className="icon-line-top"></i>
                      <i className="icon-line-center"></i>
                      <i className="icon-line-bottom"></i>
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </div>

          <div className="navbar-menu">
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <div className="navbar-item">
                <Link
                  id="#signup-btn"
                  to="https://app.tasktility.com/app/login"
                  className="button button-signup btn-outlined is-bold btn-align light-btn rounded raised"
                >
                  {translationMessages.login}
                </Link>
              </div>
              <div className="navbar-item">
                <div
                  class="dropdown navbar-fade navbar-light"
                  style={{ width: "100%" }}
                >
                  <button class="button button-signup btn-outlined is-bold btn-align light-btn rounded raised">
                    <i className="im im-icon-Globe" style={{ fontSize: 18 }} />{" "}
                    {getActiveLanguage()}
                  </button>
                  <div className="dropdown-content dropdown-content-dark">
                    <Link
                      to="/"
                      className={getActiveClass("en")}
                    >
                      <i className={getLanguageIcon("en")} />{" "}
                      {translationMessages.english}
                    </Link>
                    <Link
                      to="/de"
                      className={getActiveClass("de")}
                    >
                      <i className={getLanguageIcon("de")} />{" "}
                      {translationMessages.german}
                    </Link>
                    <Link
                      to="/ba"
                      className={getActiveClass("ba")}
                    >
                      <i className={getLanguageIcon("ba")} />{" "}
                      {translationMessages.bosnian}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div id="main-hero" className="hero-body">
        <div className="container has-text-centered">
          <div className="columns is-vcentered">
            <div className="column is-5 hero-caption">
              <h1
                className="title big-landing-title text-bold is-2"
                style={language === "ba" ? { fontSize: "2rem" } : {}}
              >
                {translationMessages.title}
              </h1>
              <h2 className="subtitle is-5 light-text pt-10 ">
                {translationMessages.subtitle1}
              </h2>
              <h2 className="subtitle is-5 light-text pb-10">
                {translationMessages.subtitle2}
              </h2>
            </div>
            <div className="column is-9">
              <figure className="image">
                <img
                  className="levitate"
                  src={hero}
                  alt="tasktility-task-management-tool"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
