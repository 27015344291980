import React from "react"
import { Helmet } from "react-helmet"
import favicon from "../../static/images/favicon.png"

export default function Layout({ children }) {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div>
      <Helmet>
        <title>Task Management And Analytics Tool | Tasktility</title>
        <meta
          name="description"
          content="Manage your organization's workflow through tasks and evaluate the work efficiency of your team with auto generated analytics."
        />
        <meta
          name="keywords"
          content="Tasks, Task Manager, Todo, Todo Lists, Analytics, Task Calendar, Task Lists, Hierarchy, Tasktility, Saas, Android, IOS, Web Application, Mobile App"
        />
        <link rel="icon" href={favicon} />
        <link
          rel="alternate"
          hreflang="en"
          href="https://www.tasktility.com/en"
        />
        <link
          rel="alternate"
          hreflang="de"
          href="https://www.tasktility.com/de"
        />
        <link
          rel="alternate"
          hreflang="ba"
          href="https://www.tasktility.com/ba"
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href="https://www.tasktility.com"
        />
        <meta
          name="google-site-verification"
          content="-Q2NO_YxqZbYO5aGdGT2EkINP2GLJB8YBmaFuEvcPbM"
        />
        <meta name="robots" content="NOODP,index,follow" />
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta property="og:title" content="Tasktility" />
        <meta
          property="og:description"
          content="Manage your organization's workflow through tasks and evaluate the work efficiency of your team with auto generated analytics"
        />
        <meta property="og:url" content="//https://www.tasktility.com" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      {children}
    </div>
  )
}
