import React from "react"
import { Link } from "gatsby"

import learn from "../../static/images/illustrations/drawings/learn.svg"
import mobile from "../../static/images/illustrations/drawings/mobile-chat.svg"
import tasks from "../../static/images/illustrations/drawings/tasks.svg"
import calendar from "../../static/images/illustrations/drawings/calendar.svg"
import analytics from "../../static/images/illustrations/drawings/analytics-2.svg"

export default function Features({ translationMessages }) {
  return (
    <>
      <div className="section is-medium">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6 has-text-centered">
              <img className="featured-svg levitate" src={learn} alt="web-application" />
            </div>
            <div className="column is-4 is-offset-1">
              <div className="icon-subtitle">
                <i className="im im-icon-Laptop"></i>
              </div>
              <h1 className="title quick-feature is-handwritten">
                {translationMessages.webApplication.title}
                <span className="bg-number text-bold">2</span>
              </h1>
              <div className="title-divider is-small"></div>
              <span className="section-feature-description">
                {translationMessages.webApplication.description}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-feature-grey is-medium is-relative is-skewed-sm">
        <div className="container is-reverse-skewed-sm">
          <div className="columns is-vcentered">
            <div className="column is-4 is-offset-2">
              <div className="icon-subtitle">
                <i className="im im-icon-Smartphone-3"></i>
              </div>
              <h1 className="title quick-feature is-handwritten">
                {translationMessages.mobileApp.title}
                <span className="bg-number text-bold">3</span>
              </h1>
              <div className="title-divider is-small"></div>
              <span className="section-feature-description">
                {translationMessages.mobileApp.description}
              </span>
            </div>
            <div className="column is-6 has-text-centered">
              <img className="featured-svg levitate" src={mobile} alt="mobile-app" />
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6 has-text-centered">
              <img className="featured-svg levitate" src={tasks} alt="task-management" />
            </div>
            <div className="column is-4 is-offset-1">
              <div className="icon-subtitle">
                <i className="im im-icon-Check-2"></i>
              </div>
              <h1 className="title quick-feature is-handwritten">
                {translationMessages.taskManagement.title}
                <span className="bg-number text-bold">4</span>
              </h1>
              <div className="title-divider is-small"></div>
              <span className="section-feature-description">
                {translationMessages.taskManagement.description}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        id="features"
        className="section section-feature-grey is-relative is-medium is-skewed-sm"
      >
        <div className="container is-reverse-skewed-sm">
          <div className="columns is-vcentered">
            <div className="column is-4 is-offset-2">
              <div className="icon-subtitle">
                <i className="im im-icon-Calendar"></i>
              </div>
              <h2 className="title quick-feature is-handwritten">
                {translationMessages.taskCalendar.title}
                <span className="bg-number text-bold">5</span>
              </h2>
              <div className="title-divider is-small"></div>
              <span className="section-feature-description">
                {translationMessages.taskCalendar.description}
              </span>
            </div>
            <div className="column is-6 has-text-centered">
              <img className="featured-svg levitate" src={calendar} alt="task-calender" />
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6 has-text-centered">
              <img className="featured-svg levitate" src={analytics} alt="task-analytics" />
            </div>
            <div className="column is-4 is-offset-1">
              <div className="icon-subtitle">
                <i className="im im-icon-Bar-Chart3"></i>
              </div>
              <h1 className="title quick-feature is-handwritten">
                {translationMessages.analytics.title}
                <span className="bg-number text-bold">6</span>
              </h1>
              <div className="title-divider is-small"></div>
              <span className="section-feature-description">
                {translationMessages.analytics.description}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium is-relative">
        <div className="container has-text-centered">
          <div className="columns is-vcentered">
            <div className="column is-6 is-offset-3 has-text-centered">
              <div className="section-title-wrapper has-text-centered">
                <div className="bg-number">7</div>
                <h1 className="section-title-landing">
                  {" "}
                  {translationMessages.thatsNotAll}
                </h1>
                <h4>{translationMessages.discoverMoreFeatures}</h4>
              </div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="columns is-vcentered">
              <div className="column is-4">
                <div className="content content-flex">
                  <div className="dark-text">
                    <i className="im im-icon-Tag-4 is-size-2 color-primary"></i>
                  </div>
                  <div className="dark-text has-text-left ml-30">
                    <h5 className="is-landing primary-text text-bold pt-5 pb-5">
                      {translationMessages.tags.title}
                    </h5>
                    {translationMessages.tags.description}
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="content content-flex">
                  <div className="dark-text">
                    <i className="im im-icon-Tree-2 is-size-2 color-primary"></i>
                  </div>
                  <div className="dark-text has-text-left ml-30">
                    <h5 className="is-landing primary-text text-bold pt-5 pb-5">
                      {translationMessages.hierarchyTree.title}
                    </h5>
                    {translationMessages.hierarchyTree.description}
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="content content-flex">
                  <div className="dark-text">
                    <i className="im im-icon-Bell is-size-2 color-primary"></i>
                  </div>
                  <div className="dark-text has-text-left ml-30">
                    <h5 className="is-landing primary-text text-bold pt-5 pb-5">
                      {translationMessages.notifications.title}
                    </h5>
                    {translationMessages.notifications.description}
                  </div>
                </div>
              </div>
            </div>
            <div className="has-text-centered is-title-reveal pt-40 pb-40">
              <Link
                to="#form"
                className="button button-cta btn-align raised rounded secondary-btn"
              >
                {translationMessages.startYourFreeTrial}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
