import React from "react"
import { Link } from "gatsby"

export default function Pricing({ translationMessages }) {
  return (
    <div className="section is-medium is-relative section-feature-grey">
      <div className="container">
        <div className="section-title-wrapper has-text-centered">
          <div className="bg-number">8</div>
          <h2 className="section-title-landing">{translationMessages.title}</h2>
          <h4>{translationMessages.subtitle}</h4>
          <h4> {translationMessages.billed}</h4>
        </div>

        <div className="content-wrapper">
          <div className="section is-medium mt-30">
            <div className="container">
              <div id="fancy-pricing" className="fancy-pricing">
                <div className="columns is-vcentered">
                  <div className="column is-4">
                    <div className="flex-card fancy-pricing-card light-bordered hover-inset secondary">
                      <h3 className="plan-name">
                        {translationMessages.starter.title}
                      </h3>
                      <div className="plan-icon">
                        <i className="im im-icon-Shop-4"></i>
                      </div>
                      <div className="plan-price">
                        <small>€</small>
                        {translationMessages.starter.price}
                        <small>{translationMessages.unit}</small>
                      </div>
                      <ul className="plan-features">
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.starter.users}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.starter.support}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.starter.trial}
                          </span>
                        </li>
                      </ul>
                      <div className="pt-10 pb-10">
                        <Link
                          to="#form"
                          className="button button-cta btn-align rounded raised secondary-btn btn-outlined is-bold"
                        >
                          {translationMessages.getStarted}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">
                    <div className="flex-card fancy-pricing-card light-bordered hover-inset secondary">
                      <h3 className="plan-name">
                        {translationMessages.basic.title}
                      </h3>
                      <div className="plan-icon">
                        <i className="im im-icon-Shop-2"></i>
                      </div>
                      <div className="plan-price">
                        <small>€</small>
                        {translationMessages.basic.price}
                        <small>{translationMessages.unit}</small>
                      </div>
                      <ul className="plan-features">
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.basic.users}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.basic.support}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.basic.trial}
                          </span>
                        </li>
                      </ul>
                      <div className="pt-10 pb-10">
                        <Link
                          to="#form"
                          className="button button-cta btn-align rounded raised secondary-btn is-bold"
                        >
                          {translationMessages.getStarted}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">
                    <div className="flex-card fancy-pricing-card light-bordered hover-inset secondary">
                      <h3 className="plan-name">
                        {translationMessages.individual.title}
                      </h3>
                      <div className="plan-icon">
                        <i className="im im-icon-MaleFemale"></i>
                      </div>
                      <div className="plan-price">
                        <small>€</small>
                        {translationMessages.individual.price}
                        <small>{translationMessages.unit}</small>
                      </div>
                      <ul className="plan-features">
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.individual.users}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.individual.support}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.individual.trial}
                          </span>
                        </li>
                      </ul>
                      <div className="pt-10 pb-10">
                        <Link
                          to="#form"
                          className="button button-cta btn-align rounded raised secondary-btn btn-outlined is-bold"
                        >
                          {translationMessages.getStarted}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns is-vcentered">
                  <div className="column is-120 is-hidden-mobile">
                    <div className="flex-card fancy-pricing-card streched light-bordered hover-inset secondary">
                      <div>
                        <h3 className="plan-name">
                          {" "}
                          {translationMessages.enterprise.title}
                        </h3>
                      </div>
                      <div className="plan-icon">
                        <i className="im im-icon-Empire-StateBuilding"></i>
                      </div>
                      <div>
                        <div className="plan-price">
                          {translationMessages.enterprise.price}€
                          <small> {translationMessages.unit2}</small>
                        </div>
                      </div>
                      <div>
                        <ul className="plan-features">
                          <li>
                            <span className="large-count">
                              {" "}
                              {translationMessages.enterprise.users}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="ml-auto">
                        <Link
                          to="#form"
                          className="button button-cta btn-align rounded raised secondary-btn btn-outlined is-bold"
                        >
                          {translationMessages.getStarted}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4 is-hidden-desktop is-hidden-tablet">
                    <div className="flex-card fancy-pricing-card light-bordered hover-inset secondary">
                      <h3 className="plan-name">
                        {" "}
                        {translationMessages.enterprise.title}
                      </h3>
                      <div className="plan-icon">
                        <i className="im im-icon-Empire-StateBuilding"></i>
                      </div>
                      <div className="plan-price">
                        <small>€</small>
                        {translationMessages.enterprise.price}
                        <small>{translationMessages.unit2}</small>
                      </div>
                      <ul className="plan-features">
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.enterprise.users}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.enterprise.support}
                          </span>
                        </li>
                        <li>
                          <span className="feature-count-text">
                            {translationMessages.enterprise.trial}
                          </span>
                        </li>
                      </ul>
                      <div className="pt-30">
                        <Link
                          to="#form"
                          className="button button-cta btn-align rounded raised secondary-btn btn-outlined is-bold mb-20"
                        >
                          {translationMessages.getStarted}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
