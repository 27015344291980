import React from "react"
import { Link } from "gatsby"

export default function Footer({ translationMessages }) {
  return (
    <footer className="footer footer-light">
      <div className="container">
        <div className="columns footer-columns is-flex-mobile">
          <div className="column is-half-mobile">
            <div className="footer-column">
              <div className="footer-header">
                <h3>{translationMessages.contact}</h3>
              </div>
              <ul className="link-list">
                <li>
                  <Link href="mailto:info@tasktility.com">
                    Info E-mail: info@tasktility.com
                  </Link>
                </li>
                <li>
                  <Link href="mailto:support@tasktility.com">
                    Support E-mail: support@tasktility.com
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="column is-half-mobile">
            <div className="footer-column">
              <div className="footer-header">
                <h3>{translationMessages.development}</h3>
              </div>
              <div className="copyright">
                <ul className="link-list">
                  <li>
                    <Link to="https://www.linkedin.com/in/adnan-zalihi%C4%87-8b598b162/?originalSubdomain=de">
                      Adnan Zalihic
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.mirzamusic.me">Mirza Music (OD IMI Soft)</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  )
}
